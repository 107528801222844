import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Chart from '../../../components/Chart'
import imageMarker from '/images/marker.png';
import CheckbooxButton from '../../../components/CheckbooxButton'
import FilterKRT from './FilterKRT'
import { Button } from 'antd';

const DivBlurBottom = styled.div`
  width: 24rem;
  height: 550px;
  overflow-y: scroll;
  position: relative;
  &:after {
    content: '';
    position: fixed;
    left: 0px;
    right: 0px;
    height: 10%;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(139,167,32,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none;
  }
`

let MAP_TEXT = {
  'province_name': 'จังหวัด',
  'city_name': 'อำเภอ',
  'school': ''
}

const TitleTab = ({ data, filter, count }) => {
  let text = ''
  if (filter.view === 'country') {
    return (
      <p className='text-center text-sm xl:text-lg font-baijam'>
        ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน ได้สนับสนุนโครงการจำนวน  <span className='text-[#038967] text-2xl'>{count.sum_project_group.toLocaleString("en-US")}</span> โครงการ <br />
        ครอบคลุมกลุ่มเป้าหมาย <span className='text-[#038967] text-2xl'>{(count.sum_target_group).toLocaleString("en-US")}</span> คน ใน<span className='text-[#038967] text-2xl'>{(data['coverProvince' + filter.year]).toLocaleString("en-US")}</span> จังหวัด
      </p>
    )
  } else {
    return (
      <p className='text-center text-sm xl:text-lg font-baijam'>
        ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน ได้สนับสนุนโครงการจำนวน  <span className='text-[#038967] text-2xl'>{count.sum_project_group.toLocaleString("en-US")}</span> โครงการ <br />
        ครอบคลุมกลุ่มเป้าหมาย <span className='text-[#038967] text-2xl'>{(count.sum_target_group).toLocaleString("en-US")}</span> คน ในจังหวัด
        {`${filter.province_name} ${filter.city_name ? 'อำเภอ' + filter.city_name : ''} ${filter.tumbon_name ? 'ตำบล' + filter.tumbon_name : ''}`}
      </p>
    )
  }
}

const CardItem = (props) => {
  return (<div
    onClick={() => props.onClickButton(props)}
    className='w-11/12 justify-center rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 mx-2 mb-4 bg-white hover:bg-gray-100'
  >
    <div className='flex flex-row justify-between'>
      <div className='flex flex-row'>
        <div className='flex flex-col'>
          <span className='text-lg font-bold text-center'>{MAP_TEXT[props.type]}{props.key_group}</span>
        </div>
      </div>

    </div>
    <div className="w-full h-[1px] bg-gray-300 mb-2" > </div>
    <div className='flex flex-row justify-between '>

      {
        props.type === 'school' ?
          <div className='flex flex-col'>
            <div className='flex flex-row'>
              <span className='text-md font-bold text-left mr-2'>สนับสนุนโครงการ</span>
              <span className='text-md font-bold text-left text-green-500'>  {props.sum_project_group} โครงการ</span>
            </div>
            <div className='flex flex-row'>
              <span className='text-md font-bold text-left mr-2'>จำนวนผู้ได้รับทุน</span>
              <span className='text-md font-bold text-left text-green-500'>  {props.sum_target_group} คน</span>
            </div>
          </div>
          :
          <div className='flex flex-col'>
            <div className='flex flex-row'>
              <span className='text-md font-bold text-left mr-2'>หน่วยจัดการเรียนรู้ที่เข้าร่วม</span>
              <span className='text-md font-bold text-left text-green-500'>  {props.sum_organization} หน่วยงาน</span>
            </div>
            <div className='flex flex-row'>
              <span className='text-md font-bold text-left mr-2'>สนับสนุนโครงการ</span>
              <span className='text-md font-bold text-left text-green-500'>  {props.sum_project_group} โครงการ</span>
            </div>
            <div className='flex flex-row'>
              <span className='text-md font-bold text-left mr-2'>จำนวนผู้ได้รับทุน</span>
              <span className='text-md font-bold text-left text-green-500'>  {props.sum_target_group} คน</span>
            </div>
          </div>
      }
    </div>
  </div >
  )
}

const MapAreaView = (props) => {

  let { filter, setFilter } = props

  let {
    organizationJoinAll,
    helpStudentsAll,
    organization2562,
    organization2563,
    organization2564,
  } = props.data

  console.log("props", props)

  const [datas, setDatas] = useState([]);

  const [data, setData] = useState({
    targetgroupNorth: 0,
    schoolNorth: 0,
    targetgroupCentral: 0,
    schoolCentral: 0,
    targetgroupNortheast: 0,
    schoolNortheast: 0,
    targetgroupSouth: 0,
    schoolSouth: 0,
    targetgroup_sum: 0,
    targetgroup_school: 0,
    sum_project_group: 0,
    name: '',

    count_province_north: 0,
    count_province_central:  0,
    count_province_northeast: 0,
    count_province_south:  0,
  
    count_project_north:  0,
    count_project_central:  0,
    count_project_northeast:  0,
    count_project_south:  0,
  });

  useEffect(() => {
    let result = {}
    let _data = []
    let _dataForSum = []
    let key_group = 'province_name'
    let name = ''
    console.log(filter)
    if (filter.year === "2562") {
      _dataForSum = [...organization2562]
      _data = [...organization2562]
    } else if (filter.year === "2563") {
      _dataForSum = [...organization2563]
      _data = [...organization2563]
    } else {
      _dataForSum = [...organization2564]
      _data = [...organization2564]
    }
    if (filter.view === 'country') {
      key_group = 'province_name'
      _data = _data.map(x => ({ ...x, type: 'province_name' }))
    } else if (filter.view === 'province') {
      key_group = 'city_name'
      name = 'อำเภอ' + filter.city_name
      _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
    } else if (filter.view === 'city') {
      key_group = 'hostname'
      name = 'จังหวัด' + filter.province_name
      _data = _data.filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name).map(x => ({ ...x, type: 'school' }))
    }

    if (filter.region !== 'ทั้งหมด') _data = _data.filter(item => item.region === filter.region)

    console.log(_data)

    _data.forEach(element => {
      if (!result[element[key_group]]) {
        result[element[key_group]] = {
          sum_school: 1,
          key_group: element[key_group],
          sum_target_group: element.sum_target_group,
          sum_project_group: element.project_group.length,
          type: element.type,
          sum_organization: 1,
          // หน่วยงานที่เข้าร่วม
        }
        if (element.type === 'school') {
          result[element[key_group]]['data'] = element
        }
      } else {
        result[element[key_group]].sum_school += 1
        result[element[key_group]].sum_organization += 1
        result[element[key_group]].sum_target_group += element.sum_target_group
        result[element[key_group]].sum_project_group += element.project_group.length
      }
    });
    console.log("props _dataForSum", _dataForSum)
    console.log("props [...Object.values(result)]", [...Object.values(result)])
    setDatas([...Object.values(result)])
    setData({
      targetgroupNorth: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคเหนือ").reduce((acc, obj) => obj.sum_target_group + acc, 0),
      schoolNorth: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคเหนือ").length,
      targetgroupCentral: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคกลาง").reduce((acc, obj) => obj.sum_target_group + acc, 0),
      schoolCentral: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคกลาง").length,
      targetgroupNortheast: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคตะวันออกเฉียงเหนือ").reduce((acc, obj) => obj.sum_target_group + acc, 0),
      schoolNortheast: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคตะวันออกเฉียงเหนือ").length,
      targetgroupSouth: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคใต้").reduce((acc, obj) => obj.sum_target_group + acc, 0),
      schoolSouth: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคใต้").length,
      targetgroup_sum: [...Object.values(result)].reduce((acc, obj) => obj.sum_target_group + acc, 0),
      targetgroup_school: [...Object.values(result)].length,
      sum_project_group: [...Object.values(result)].reduce((acc, obj) => obj.sum_project_group + acc, 0),

      count_province_north: [... (new Set(_dataForSum.filter(x => 'ภาค' + x.region === "ภาคเหนือ").map(x => x.province_name)))].length,
      count_province_central: [... (new Set(_dataForSum.filter(x => 'ภาค' + x.region === "ภาคกลาง").map(x => x.province_name)))].length,
      count_province_northeast: [... (new Set(_dataForSum.filter(x => 'ภาค' + x.region === "ภาคตะวันออกเฉียงเหนือ").map(x => x.province_name)))].length,
      count_province_south: [... (new Set(_dataForSum.filter(x => 'ภาค' + x.region === "ภาคใต้").map(x => x.province_name)))].length,

      count_project_north: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคเหนือ").reduce((acc, obj) => obj.project_group.length + acc, 0),
      count_project_central: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคกลาง").reduce((acc, obj) => obj.project_group.length + acc, 0),
      count_project_northeast: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคตะวันออกเฉียงเหนือ").reduce((acc, obj) => obj.project_group.length + acc, 0),
      count_project_south: _dataForSum.filter(x => 'ภาค' + x.region === "ภาคใต้").reduce((acc, obj) => obj.project_group.length + acc, 0),
    })
  }, [filter])

  return (
    <div>
      <div className='w-[full] flex justify-center'>
        <div className=' flex flex-col' >
          <div className=' bg-white flex flex-row   border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
            <div className=' flex flex-col' >
              <h5 className="text-sm m-0 ml-[9px]" >ปีการศึกษา: </h5>
              <div className='flex flex-row' >
                <CheckbooxButton title='2564' checked={filter.year === '2564'} onClick={() => {
                  filter.year = '2564'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2563' checked={filter.year === '2563'} onClick={() => {
                  filter.year = '2563'
                  setFilter({ ...filter })
                }} />
                <CheckbooxButton title='2562' checked={filter.year === '2562'} onClick={() => {
                  filter.year = '2562'
                  setFilter({ ...filter })
                }} />
              </div>
            </div>
            < FilterKRT
              // hideCity
              data={filter}
              onChange={(res) => {
                setFilter({ ...filter, ...res })
              }}
            />
          </div>
        </div>
      </div>


      <TitleTab
        data={props.data}
        filter={filter}
        count={{
          count_city: data.count_city,
          count_tumbon: data.count_tumbon,
          count_tpsschool: data.tpsschool,
          sumtps: data.targetgroup_sum,
          sum_project_group: data.sum_project_group,
          sum_target_group: data.targetgroup_sum,
        }} />
      {/* <div className="ml-6">จำนวนหน่วยจัดการเรียนรู้  <span className='text-md font-bold text-center text-blue-600'>{data.targetgroup_school}</span>  องค์กร</div>
              <div className="ml-6">จำนวนผู้ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.targetgroup_sum}</span>  คน</div>
              <div className="ml-6">จำนวนโครงการ  <span className='text-md font-bold text-center text-green-600'>{data.targetgroup_sum}</span>  คน</div> */}

      <div className="flex flex-col md:flex-row justify-center">
        <div className='w-full md:w-96 h-min '>
          {filter.view === 'country' ?
            <div>
              <div className='w-[200px] flex flex-row '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} />
                <div className='text-md font-bold text-center'>ภาคเหนือ</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-md font-bold text-center text-blue-600'>{data.count_province_north}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนหน่วยจัดการเรียนรู้  <span className='text-md font-bold text-center text-blue-600'>{data.schoolNorth}</span>  องค์กร</div>
              <div className="ml-6">จำนวนผู้ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.targetgroupNorth}</span>  คน</div>
              <div className="ml-6">จำนวนโครงการ  <span className='text-md font-bold text-center text-green-600'>{data.count_project_north}</span>  โครงการ</div>

              <div className='w-[200px] flex flex-row mt-3 '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} />
                <div className='text-md font-bold text-center'>ภาคตะวันออกเฉียงเหนือ</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-md font-bold text-center text-blue-600'>{data.count_province_northeast}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนหน่วยจัดการเรียนรู้  <span className='text-md font-bold text-center text-blue-600'>{data.schoolNortheast}</span>  องค์กร</div>
              <div className="ml-6">จำนวนผู้ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.targetgroupNortheast}</span>  คน</div>
              <div className="ml-6">จำนวนโครงการ  <span className='text-md font-bold text-center text-green-600'>{data.count_project_northeast}</span>  โครงการ</div>

              <div className='w-[200px] flex flex-row mt-3 '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} />
                <div className='text-md font-bold text-center'>ภาคใต้</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-md font-bold text-center text-blue-600'>{data.count_province_south}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนหน่วยจัดการเรียนรู้  <span className='text-md font-bold text-center text-blue-600'>{data.schoolSouth}</span>  องค์กร</div>
              <div className="ml-6">จำนวนผู้ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.targetgroupSouth}</span>  คน</div>
              <div className="ml-6">จำนวนโครงการ  <span className='text-md font-bold text-center text-green-600'>{data.count_project_south}</span>  โครงการ</div>

              <div className='w-[200px] flex flex-row mt-3 '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} />
                <div className='text-md font-bold text-center'>ภาคกลาง</div>
              </div>
              <div className="ml-6">จำนวนจังหวัด <span className='text-md font-bold text-center text-blue-600'>{data.count_province_central}</span>  จังหวัด</div>
              <div className="ml-6">จำนวนหน่วยจัดการเรียนรู้  <span className='text-md font-bold text-center text-blue-600'>{data.schoolCentral}</span>  องค์กร</div>
              <div className="ml-6">จำนวนผู้ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.targetgroupCentral}</span>  คน</div>
              <div className="ml-6">จำนวนโครงการ  <span className='text-md font-bold text-center text-green-600'>{data.count_project_central}</span>  โครงการ</div>
            </div>
            :
            <div>
              <div className='w-[200px] flex flex-row '>
                <img className="flex self-center" src={imageMarker} alt="image for banner" width={'24px'} />
                <div className='text-md font-bold text-center'>จังหวัด{filter.province_name}</div>
              </div>
              <div className="ml-6">จำนวนหน่วยจัดการเรียนรู้  <span className='text-md font-bold text-center text-blue-600'>{data.targetgroup_school}</span>  องค์กร</div>
              <div className="ml-6">จำนวนผู้ได้รับทุน  <span className='text-md font-bold text-center text-green-600'>{data.targetgroup_sum}</span>  คน</div>
              <div className="ml-6">จำนวนโครงการ  <span className='text-md font-bold text-center text-green-600'>{data.sum_project_group}</span>  โครงการ</div>

            </div>
          }
        </div>
        <DivBlurBottom>
          {datas.map(x => (<CardItem {...x}
            onClickButton={(res) => {
              if (x.type === 'province_name') {
                setFilter({
                  ...filter,
                  ...{
                    view: 'province',
                    province_name: res.key_group
                  }
                })
              } else if (x.type === 'city_name') {
                setFilter({
                  ...filter,
                  ...{
                    view: 'city',
                    city_name: res.key_group
                  }
                })
              } else if (x.type === 'school') {
                // tumbon} อำเภอ{modalSchool?.city
                props.ShowModalSchool({ ...x.data, ... { tumbon: x.data.tumbon_name, city: x.data.city_name } })
                // setFilter({
                //   ...filter,
                //   ...{
                //     view: 'city_name',
                //     city_name: res.key_group
                //   }
                // })
              }
            }}
          />))}
        </DivBlurBottom>
      </div>
    </div >
  )
}

export default MapAreaView
