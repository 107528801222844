import React, { useState, useEffect } from 'react';
import Chart from '../../../components/Chart'
import imageStudentsCapital from '/images/vec-1.png';
import imageProvinceJoin from '/images/targetgroup-8.png';
import imageOanization from '/images/targetgroup-9.png';
import imageSchool from '/images/targetgroup-7.png';
import MapSvgRegion from '../../../components/MapSvgRegion';
import MapSvgNode from '../../../components/MapSvgNode';
import CheckbooxButton from '../../../components/CheckbooxButton'
const Overview = (props) => {
  console.log(props)
  const [data, setData] = useState(props.data);

  let { filter, setFilter } = props

  const [dataProject, setDataProject] = useState({});
  console.log("dataProject", dataProject)
  const [provinceSum, setProvinceSum] = useState([])

  useEffect(() => {
    if(props.data){
      console.log('props.data',props.data)
      setDataProject({
        north: props.data['organization' + filter.year].filter(x => 'ภาค' + x.region === "ภาคเหนือ").reduce((acc, value) => (acc + value.project_group.length), 0),
        central: props.data['organization' + filter.year].filter(x => 'ภาค' + x.region === "ภาคกลาง").reduce((acc, value) => (acc + value.project_group.length), 0),
        northeast: props.data['organization' + filter.year].filter(x => 'ภาค' + x.region === "ภาคตะวันออกเฉียงเหนือ").reduce((acc, value) => (acc + value.project_group.length), 0),
        south: props.data['organization' + filter.year].filter(x => 'ภาค' + x.region === "ภาคใต้").reduce((acc, value) => (acc + value.project_group.length), 0)
      });
  
      const province = [...new Set(props.data['organization' + filter.year].map(item => item.province_id))].map(item => { return { id: item } });
      props.data['organization' + filter.year].forEach((item) => {
        let count = item.project_group.length
        // 1
        let index = province.findIndex(ele => ele.id === item.province_id)
        // if(Calculate.Percentile(90, props.data['school' + filter.year].map(item => item.krt)))
        !province[index]['count'] ? province[index]['count'] = count : province[index]['count'] += count
      })
      let sum = province.length > 0 ? province.map(item => item.count).reduce((a, b) => a + b) : 0
      province.map(item => {
        let avg = sum / province.length
        let color = 0
        if (item.count > avg * 1.5) { color = '#008442' }
        else if (item.count > avg) { color = '#88cd92' }
        else if (item.count > avg / 2) { color = '#d7eedb' }
        else { color = color = '#ffffff' }
        item.color = color
        return item
      })
      setProvinceSum(province)
    }
  }, [filter,props.data])

  return (
    <div>
      <p className='text-center text-sm xl:text-lg font-baijam'>
        {/* ตั้งแต่ปี พ.ศ. 2562 จนถึงปัจจุบันทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน <br /> */}
        ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐานเริ่มช่วยเหลือตั้งแต่ปีการศึกษา 2562 จนถึง 2564<br />
        {/* ตั้งแต่ปี พ.ศ. 2562 จนถึงปัจจุบันทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน <br /> */}
        มีหน่วยจัดการเรียนรู้ที่เข้าร่วม  <span className='text-[#038967] text-2xl'>{(data['organizationJoinAll']).toLocaleString("en-US")}</span> หน่วยจัดการเรียนรู้ กลุ่มเป้าหมายที่ได้รับทุน <span className='text-[#038967] text-2xl'>{data['helpStudentsAll'].toLocaleString("en-US")}</span> คน <br /> ภายใต้โครงการ  <span className='text-[#038967] text-2xl'>{data['projectAll'].length.toLocaleString("en-US")}</span> โครงการ
      </p>

      <div className='w-[full] flex justify-center mb-6'>
        <div className='w-[320px] bg-white flex flex-row border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
          <div className="mr-2" style={{ marginTop: 8, pading: 0 }}>ปีการศึกษา</div>
          <CheckbooxButton title='2564' checked={filter.year === '2564'} onClick={() => {
            filter.year = '2564'
            setFilter({ ...filter })
          }} />
          <CheckbooxButton title='2563' checked={filter.year === '2563'} onClick={() => {
            filter.year = '2563'
            setFilter({ ...filter })
          }} />
          <CheckbooxButton title='2562' checked={filter.year === '2562'} onClick={() => {
            filter.year = '2562'
            setFilter({ ...filter })
          }} />
        </div>
      </div>

      <div className='grid grid-cols-12 gap-4'>

        <div className='col-span-12 lg:col-span-12 bg-white'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <Chart
              containerProps={{ style: { height: "200px" } }}
              className='p-4'
              options={{
                type: 'areaspline',
                title: 'จำนวนผู้ได้รับทุนตั้งแต่ 2562 ถึง 2564',
                categories: ['2562', '2563', '2564'],
                yAxis: {
                  title: {
                    text: 'จำนวนนักเรียน (คน)',
                  }
                },
                legend: {
                  align: 'center',
                  verticalAlign: 'bottom',
                  x: 0,
                  y: 0
                },
              }}
              data={
                [{
                  name: 'จำนวนผู้ได้รับทุน',
                  marker: {
                    symbol: 'square'
                  },
                  data: [data.helpStudents2562, data.helpStudents2563, data.helpStudents2564],
                  color: '#ffb280'
                }]
              } />
          </div>
        </div>


        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4 w-[72px]" src={imageStudentsCapital} alt="image for banner" />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>ผู้ได้รับทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data['helpStudents' + filter.year].toLocaleString("en-US")}</p>
              <span className='text-md font-bold text-center'>คน</span>
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4 w-[72px]" src={imageSchool} alt="image for banner" />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>โครงการที่เข้าร่วม</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{(data['project' + filter.year].length).toLocaleString("en-US")}</p>
              <span className='text-md font-bold text-center'>โครงการ</span>
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4 w-[72px]" src={imageOanization} alt="image for banner" />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>หน่วยจัดการเรียนรู้ที่เข้าร่วม</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{(data['organizationJoin' + filter.year]).toLocaleString("en-US")}</p>
              <span className='text-md font-bold text-center'>องค์กร</span>
            </div>
          </div>
        </div>

        <div className='col-span-12 md:col-span-6 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            <img className="flex self-center xl:mx-4 w-[72px]" src={imageProvinceJoin} alt="image for banner" />
            <div className='flex flex-1 flex-col'>
              <span className='text-sm xl:text-xl font-bold text-center'>ครอบคลุมพื้นที่</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{(data['coverProvince' + filter.year]).toLocaleString("en-US")}</p>
              <span className='text-md font-bold text-center'>จังหวัด</span>
            </div>
          </div>
        </div>

      </div>

      <p className='text-center text-[#038967] font-bold mb-6 text-xl lg:text-2xl mt-20'>แผนที่แสดงจำนวนโครงการ</p>
      <div className='grid grid-cols-12 gap-1'>
        <div className='col-span-12 lg:col-span-6 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <div className='flex flex-col p-4'>
              <span className='text-md font-bold text-center mb-4'>แผนที่แสดงสัดส่วนโครงการแบ่งตามภูมิภาค</span>
              <MapSvgRegion
                unit="โครงการ"
                data={dataProject}
              />
            </div>
          </div>
        </div>

        <div className='col-span-12 lg:col-span-6 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <div className='flex flex-col p-4'>
              <span className='text-md font-bold text-center mb-4'>แผนที่แสดงสัดส่วนโครงการแบ่งตามภูมิภาค</span>
              <MapSvgNode data={provinceSum} unit="โครงการ" />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Overview
