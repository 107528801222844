import React, { useState, useEffect, useRef } from "react";
import Layoutas from "../../components/Layout";
import Mapbox from "../../mapbox";
import LegendCard from "../../mapbox/LegendCard";
import Calculate from "../../util/calculate";

import Sidetab from "../../components/Sidetab";
import Overview from "./map_target_group_contents/Overview";
import MapAreaView from "./map_target_group_contents/MapAreaView";
import { Modal, Divider } from "antd";
import "../index.css";

import Star from "/images/organization.svg";
// import Dot from '/images/dot.svg';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TumbonAddr from "../../util/TumbonAddr";
import region from "../../util/region";
import api from "../../util/api";
import getProvinceName from "../../util/getProvinceName";

const FullMap = (props) => {
  const [initData, setInitData] = React.useState(null);
  const [open, setOpen] = useState(true);
  const [tabvalue, setTabValue] = React.useState(0);

  const [modalUniversity, setModalUniversity] = useState({
    visible: false,
  });

  const [data_map, setDataMap] = useState([]);
  const [border_color] = useState([
    "#1b5e20",
    "#388e3c",
    "#66bb6a",
    "#a5d6a7",
    "#94a3b8",
  ]);
  const is_Click = useRef(false);

  const [legend_data, setLegendData] = useState({
    title: "",
    subtitle: "",
    data: [],
    footer: "",
  });

  const [filter, setFilter] = React.useState({
    minView: "country",
    view: "country",
    year: "2564",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });

  useEffect(async () => {
    let is_login = api.isLogin().status;
    let res_profile = await (is_login ? api.getProfileDb() : null);
    let res_target_group = await api.getInitData("target_group");
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId;
        setInitData({
          ...res_target_group.deepdata.province[getProvinceName(pid)],
          organization2562: res_target_group.deepdata.organization2562.filter(
            (item) => item.province_name === getProvinceName(pid)
          ),
          organization2563: res_target_group.deepdata.organization2563.filter(
            (item) => item.province_name === getProvinceName(pid)
          ),
          organization2564: res_target_group.deepdata.organization2564.filter(
            (item) => item.province_name === getProvinceName(pid)
          ),
          organization2565: res_target_group.deepdata.organization2565.filter(
            (item) => item.province_name === getProvinceName(pid)
          ),
        });
        setFilter({
          ...filter,
          minView: "province",
          view: "province",
          province_name: getProvinceName(pid),
        });
      } else {
        setInitData({ ...res_target_group.deepdata });
      }
    }
  }, []);

  // useEffect(async () => {
  //   let [res_target_group] = await Promise.all([api.getInitData('target_group')])
  //   setInitData({
  //     ...res_target_group.deepdata
  //   })
  // }, [])

  useEffect(async () => {
    if (initData) {
      let dataContextUniversity = initData['organization' + filter.year]
      if (filter.region !== 'ทั้งหมด') dataContextUniversity = dataContextUniversity.filter(item => item.region === filter.region)
      let response = onProcessData(
        {
          province: filter["province_name"],
          city: filter["city_name"],
          tumbon: filter["tumbon_name"],
          layer: filter["view"],
        },
        convertMapContent(filter.view, dataContextUniversity)
      );
      let data_organization = [
        ...new Set(
          dataContextUniversity.map((item) => {
            return {
              ...item,
              project_sum: item.project_group.map((project) => {
                return {
                  name: project,
                  count: initData[`project${filter.year}`].find(
                    (ele) => ele.name === project
                  ).count,
                };
              }),
            };
          })
        ),
      ];
      if (!filter.province_name || filter.province_name !== "ทั้งหมด") {
        if (filter.city_name !== null) {
          data_organization = [
            ...new Set(
              dataContextUniversity.filter(
                (item) =>
                  item.province_name === filter.province_name &&
                  item.city_name === filter.city_name
              )
            ),
          ];
        } else {
          data_organization = [
            ...new Set(
              dataContextUniversity.filter(
                (item) => item.province_name === filter.province_name
              )
            ),
          ];
        }
      }
      setDataMap([
        { id: "border", layer: filter.view, data: response.data },
        {
          id: "organization",
          layer: "marker",
          data: data_organization.map((item) => ({
            ...item,
            type: "organization",
          })),
          icon: Star,
        },
      ]);

      let data_legend = [
        {
          name: "จำนวนนักเรียนที่ได้รับทุน",
          value: response.data.reduce((acc, cur) => acc + cur.target_group, 0),
          unit: "คน",
        },
        {
          name: "จำนวนหน่วยจัดการเรียนรู้ที่เข้าร่วม",
          value: response.data.reduce(
            (acc, cur) => acc + cur.organize_count,
            0
          ),
          unit: "องค์กร",
        },
        {
          name: "จำนวนโครงการ",
          value: response.data.reduce((acc, cur) => acc + cur.project_count, 0),
          unit: "โครงการ",
        },
      ];
      setLegendData({
        ...legend_data,
        title: filter.province_name,
        subtitle: filter.city_name,
        data: data_legend,
      });
    }
  }, [filter, initData]);

  const updateFilter = (res) => {
    setFilter({ ...res });
  };

  let tab_content = [
    {
      title: "ภาพรวม",
      content: (
        <Overview data={initData} filter={filter} setFilter={updateFilter} />
      ),
    },
    {
      title: "ดูตามพื้นที่",
      content: (
        <MapAreaView
          data={initData}
          filter={filter}
          ShowModalSchool={(data) => {
            setModalUniversity({
              visible: true,
              ...data,
            });
          }}
          setFilter={updateFilter}
        />
      ),
    },
  ];

  const convertMapContent = (layer, data) => {
    let res = [];
    const province = [
      ...new Set(TumbonAddr.map((item) => item.provincename)),
    ].map((item) => {
      return {
        region: region.getRegion(item),
        province: item,
        total: 0,
        target_group: 0,
        organize_count: 0,
        project_count: 0,
      };
    });
    const city = [
      ...new Set(
        TumbonAddr.map((item) => item.provincename + "_" + item.cityname)
      ),
    ].map((item) => {
      return {
        province: item.split("_")[0],
        city: item.split("_")[1],
        total: 0,
        target_group: 0,
        organize_count: 0,
        project_count: 0,
      };
    });
    const tumbon = [
      ...new Set(
        TumbonAddr.map(
          (item) =>
            item.provincename + "_" + item.cityname + "_" + item.tumbonname
        )
      ),
    ].map((item) => {
      return {
        province: item.split("_")[0],
        city: item.split("_")[1],
        tumbon: item.split("_")[2],
        total: 0,
        target_group: 0,
        organize_count: 0,
        project_count: 0,
      };
    });
    data.forEach((item) => {
      let total = item.sum_target_group;
      let count = item.sum_target_group;
      if (layer === "country") {
        let index = province.findIndex(
          (ele) => ele.province === item.province_name
        );
        if (index !== -1) {
          !province[index]["total"]
            ? (province[index]["total"] = total)
            : (province[index]["total"] += total);
          !province[index]["target_group"]
            ? (province[index]["target_group"] = count)
            : (province[index]["target_group"] += count);
          !province[index]["organize_count"]
            ? (province[index]["organize_count"] = 1)
            : (province[index]["organize_count"] += 1);
          !province[index]["project_count"]
            ? (province[index]["project_count"] = item.project_group.length)
            : (province[index]["project_count"] += item.project_group.length);
        }
        res = province;
      } else if (layer === "province") {
        let index = city.findIndex(
          (ele) =>
            ele.province === item.province_name && ele.city === item.city_name
        );
        if (index !== -1) {
          !city[index]["total"]
            ? (city[index]["total"] = total)
            : (city[index]["total"] += total);
          !city[index]["target_group"]
            ? (city[index]["target_group"] = count)
            : (city[index]["target_group"] += count);
          !city[index]["organize_count"]
            ? (city[index]["organize_count"] = 1)
            : (city[index]["organize_count"] += 1);
          !city[index]["project_count"]
            ? (city[index]["project_count"] = item.project_group.length)
            : (city[index]["project_count"] += item.project_group.length);
        }
        res = city;
      } else if (layer === "city") {
        let index = tumbon.findIndex(
          (ele) =>
            ele.province === item.province_name &&
            ele.city === item.city_name &&
            ele.tumbon ===
              (item.province_id === "10"
                ? "แขวง" + item.tumbon_name
                : item.tumbon_name)
        );
        if (index !== -1) {
          !tumbon[index]["total"]
            ? (tumbon[index]["total"] = total)
            : (tumbon[index]["total"] += total);
          !tumbon[index]["target_group"]
            ? (tumbon[index]["target_group"] = count)
            : (tumbon[index]["target_group"] += count);
          !tumbon[index]["organize_count"]
            ? (tumbon[index]["organize_count"] = 1)
            : (tumbon[index]["organize_count"] += 1);
          !tumbon[index]["project_count"]
            ? (tumbon[index]["project_count"] = item.project_group.length)
            : (tumbon[index]["project_count"] += item.project_group.length);
        }
        res = tumbon;
      } else if (layer === "tumbon") {
        let index = tumbon.findIndex(
          (ele) =>
            ele.province === item.province_name &&
            ele.city === item.city_name &&
            ele.tumbon ===
              (item.province_id === "10"
                ? "แขวง" + item.tumbon_name
                : item.tumbon_name)
        );
        if (index !== -1) {
          !tumbon[index]["total"]
            ? (tumbon[index]["total"] = total)
            : (tumbon[index]["total"] += total);
          !tumbon[index]["target_group"]
            ? (tumbon[index]["target_group"] = count)
            : (tumbon[index]["target_group"] += count);
          !tumbon[index]["organize_count"]
            ? (tumbon[index]["organize_count"] = 1)
            : (tumbon[index]["organize_count"] += 1);
          !tumbon[index]["project_count"]
            ? (tumbon[index]["project_count"] = item.project_group.length)
            : (tumbon[index]["project_count"] += item.project_group.length);
        }
        res = tumbon;
      }
    });
    if (filter.region !== "ทั้งหมด" && filter.view === "country")
      res = res.filter((item) => item.region === "ภาค" + filter.region);
    return res;
  };

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value);
      let color = "";
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color;
          break;
        }
      }
      return color;
    };

    let result = [];
    data.forEach((element) => {
      if (
        filter.province &&
        filter.province !== "ทั้งหมด" &&
        !filter.city &&
        !filter.tumbon
      ) {
        if (filter.province === element.province) {
          result.push(element);
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (
          filter.province === element.province &&
          filter.city === element.city
        ) {
          result.push(element);
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (
          filter.province === element.province &&
          filter.city === element.city &&
          filter.tumbon === element.tumbon
        ) {
          result.push(element);
        }
      } else {
        result.push(element);
      }
    });

    result.map((element) => {
      if (!isFinite(element["target_group"] / element["total"])) {
        element["percent"] = 0;
      } else {
        element["percent"] =
          (element["target_group"] /
            result
              .map((item) => item.target_group)
              .reduce((a, b) => a + b, 0)) *
          100;
      }
      return element;
    });

    let data_color = [
      {
        color: border_color[0],
        value: Calculate.Percentile(
          90,
          result.map((item) => item.target_group)
        ),
        count: 0,
        text: `มาก`,
      },
      {
        color: border_color[1],
        value: Calculate.Percentile(
          50,
          result.map((item) => item.target_group)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      {
        color: border_color[2],
        value: Calculate.Percentile(
          10,
          result.map((item) => item.target_group)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ];
    result.forEach((element) => {
      element["color"] = _genColorRange(data_color, element.target_group);
      // element['opacity'] = 0.5
      element["percent"] = parseFloat(element["percent"].toFixed(2));
    });
    if (
      filter.province &&
      filter.province !== "ทั้งหมด" &&
      !filter.city &&
      !filter.tumbon
    ) {
      result = result.filter((item) => item.province === filter.province);
    }
    let res = { data: result, notic: data_color };
    return res;
  };

  const handleChangeTab = (event, newValue) => {
    if (newValue == 0) {
      setFilter({
        ...filter,
        ...{
          view: filter.minView,
          region: "ทั้งหมด",
          province_name:
            filter.minView !== "province" ? "ทั้งหมด" : filter.province_name,
          city_name: null,
          tumbon_name: null,
        },
      });
    }
    setTabValue(newValue);
  };

  const onclickBorder = (layer, filterBorder) => {
    if (tabvalue == 0) {
      setTabValue(1);
    }
    if (layer === "tumbon") return;
    if (!is_Click.current) {
      is_Click.current = true;
      let _filter = { ...filter };
      if (layer === "province" && filterBorder.province) {
        _filter["view"] = "province";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = null;
        _filter["tumbon_name"] = null;
      } else if (layer === "city" && filterBorder.city) {
        _filter["view"] = "city";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = filterBorder.city;
        _filter["tumbon_name"] = null;
      }
      setFilter(_filter);
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true;
      console.log(property);
      setModalUniversity({
        visible: true,
        ...property,
        project_sum: JSON.parse(property.project_sum),
      });
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onBack = () => {
    let _filter = { ...filter };
    if (_filter.view === _filter.minView) return;
    if (_filter.view === "province") {
      _filter["view"] = "country";
      _filter["province_name"] = "ทั้งหมด";
      _filter["city_name"] = null;
      _filter["tumbon_name"] = null;
    } else if (_filter.view === "city") {
      _filter["view"] = "province";
      _filter["city_name"] = null;
      _filter["tumbon_name"] = null;
    }
    setFilter(_filter);
  };

  return (
    <Layoutas Role={["partner", "eef", "partner_province"]} isHideFooter>
      <div className="relative overflow-hidden">
        <div className="w-[100vw] h-[100vh]">
          <Mapbox
            id={"map-cct"}
            filter={filter}
            data={data_map}
            lng={99}
            lat={13.4}
            zoom={5.2}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={"จำนวนโรงเรียนในประเทศไทย"}
            onclickBorder={onclickBorder}
            onClickMarker={onClickMarker}
          />
          <div className="absolute top-[90px] left-2 z-1">
            <LegendCard {...legend_data} />
            <div className="justify-left mt-2 mx-auto">
              {filter.view !== filter.minView ? (
                <button
                  class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full"
                  onClick={() => onBack()}
                >
                  <ArrowBackIcon sx={{ fontSize: 18, color: "white" }} />{" "}
                  ย้อนกลับ
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="absolute bottom-[50px] lg:bottom-[10px] left-[100px] z-1 flex">
            <div className="h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3">
              <span className="font-bold center mx-1 mb-2">
                สัดส่วนคนที่ได้รับทุน
              </span>
              <div className="flex">
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}></div>
                  <div class="font-bold text-left">มาก</div>
                </div>
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}></div>
                </div>
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}></div>
                </div>
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}></div>
                  <div class="font-bold text-right">น้อย</div>
                </div>
                <div className="ml-4  flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}></div>
                  <div class="font-bold text-center">ไม่มีข้อมูล</div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-[10px] left-[470px] z-1">
            <div className="h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3">
              {/* <span className='font-bold center mx-1 mb-2'>ประเภทสถานศึกษา</span> */}
              <div class="flex flex-row">
                <div class="flex flex-row text-center ml-2">
                  <img src={Star} style={{ width: "20px", height: "20px" }} />
                  <span className="center mx-1 mb-2">องค์กร</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidetab
          title="ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน"
          tab={initData ? tab_content : []}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          titleRef="*ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน ข้อมูลจำนวนกลุ่มเป้าหมายที่ได้รับความช่วยเหลือในปีการศึกษา 2562 - 2564"
          subjectTitleRef="*ประมวลผล ณ เดือนเมษายน 2565"
          handleChangeTab={handleChangeTab}
        />
      </div>
      <Modal
        width={"1000px"}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        visible={modalUniversity.visible}
        centered={true}
        CancelText={"ปิด"}
        onCancel={() => {
          setModalUniversity({ visible: false });
        }}
      >
        <div className="text-3xl">
          {modalUniversity?.hostname} <span className="text-xl"></span>
        </div>
        <div className="text-xl">
          ที่อยู่ ตำบล{modalUniversity?.tumbon_name} อำเภอ
          {modalUniversity?.city_name} จังหวัด{modalUniversity?.province_name}
        </div>
        <Divider style={{ margin: 8 }} />
        <div className="flex flex-col">
          <span className="text-xl font-base">{`จำนวนผู้ได้รับทุน ${modalUniversity?.sum_target_group?.toLocaleString(
            "en-US"
          )} คน`}</span>
          <Divider style={{ margin: 8 }} />
          <span className="text-xl font-semibold">โครงการที่เข้าร่วม</span>
          <ul>
            {modalUniversity?.project_sum?.map((item) => {
              return (
                <li className="text-xl font-base">
                  {`${item.name} : ${item.count} คน`}
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    </Layoutas>
  );
};

export default FullMap;
