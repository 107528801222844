import * as React from "react";
// import data_school from '.';
import datacity from "./datacity.json";
import { Select } from "antd";
const { Option } = Select;

export default function Filter({
  onChange,
  data,
  optionYear = [],
  hideCity = false,
}) {
  const data_school = [];
  const [view, setView] = React.useState(data?.view);
  const [year, setYear] = React.useState(data?.year);
  const [region, setRegion] = React.useState(data?.region_name);
  const [province, setProvince] = React.useState(data?.province_name);

  // city
  return (
    <div className="code-box-shape4 queue-anim-demo flex flex-row">
      <div>
        <h5 className="text-sm m-0 ml-[9px]">ภาค : </h5>
        <Select
          showSearch
          placeholder="ภาค"
          value={data.region}
          style={{ width: 120, margin: 4 }}
          onChange={(val) => {
            onChange({ ...data, region: val });
          }}
        >
          <Option value={"ทั้งหมด"}>ทั้งหมด</Option>
          <Option value={"กลาง"}>ภาคกลาง</Option>
          <Option value={"ตะวันออกเฉียงเหนือ"}>ภาคตะวันออกเฉียงเหนือ</Option>
          <Option value={"เหนือ"}>ภาคเหนือ</Option>
          <Option value={"ใต้"}>ภาคใต้</Option>
        </Select>
      </div>

      <div>
        <h5 className="text-sm m-0 ml-[9px]">จังหวัด: </h5>
        <Select
          placeholder="จังหวัด"
          showSearch
          value={data.province_name}
          onChange={(val) => {
            if (val === "ทั้งหมด") {
              onChange({
                province_name: "ทั้งหมด",
                view: "country",
                city_name: null,
              });
            } else {
              setProvince(val);
              onChange({
                province_name: val,
                view: "province",
                city_name: null,
              });
            }
          }}
          style={{ width: 120, margin: 4, fontFamily: "Kanit" }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={"ทั้งหมด"}>{"ทั้งหมด"}</Option>
          {[, ...new Set(datacity.map((x) => x.province))]
            .filter((x) => x !== "ไม่ระบุ")
            .sort()
            .map((x) => (
              <Option value={x}>{x}</Option>
            ))}
        </Select>
      </div>

      {hideCity === false && (
        <div>
          <h5 className="text-sm m-0 ml-[9px]">อำเภอ: </h5>

          <Select
            showSearch
            value={data.city_name}
            placeholder="เลือกอำเภอ"
            style={{ width: 180, margin: 4 }}
            disabled={data.view === "country"}
            onChange={(val) => {
              if (val === "ทั้งหมด") {
                onChange({
                  // city_name: "ทั้งหมด",
                  // view: 'city_name',
                });
              } else {
                onChange({
                  city_name: val,
                  view: "city",
                });
              }
            }}
          >
            {[
              ...new Set(
                datacity
                  .filter(
                    (x) =>
                      data.province_name === x.province ||
                      data.province_name === null
                  )
                  .map((x) => x.district)
              ),
            ]
              .sort()
              .filter((x) => x !== "ไม่ระบุ")
              .map((x) => (
                <Option value={x}>{x}</Option>
              ))}
          </Select>
        </div>
      )}
    </div>
  );
}
